*, :before, :after {
  box-sizing: border-box;
}

:root {
  --background-color: white;
  --text-color: black;
  --accent-color: blue;
}

html {
  height: 100%;
}

body {
  height: 100%;
  grid-template-rows: auto 1fr auto;
  gap: 1em;
  font-family: IBM Plex Serif, serif;
  display: grid;
}

a {
  color: var(--accent-color);
  border-bottom: 1px solid;
  text-decoration: none;
}

button {
  cursor: pointer;
  font-family: IBM Plex Serif, serif;
  font-size: 1em;
}

kbd {
  background-color: #eee;
  border-radius: 5px;
  margin: .1em;
  padding: .2em .5em;
  display: inline-block;
  box-shadow: 1px 1px 5px #0000000d;
}

header {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1em 3em;
  display: flex;
}

.title {
  margin: 0;
}

.title a {
  color: var(--text-color);
  border: none;
}

header .nav {
  display: none;
}

@media (min-width: 800px) {
  header .nav {
    display: block;
  }
}

header .nav.open {
  width: 100%;
  height: 100%;
  z-index: 1;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.navToggle {
  z-index: 2;
  font-size: 2em;
  font-family: var(--font-family);
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  display: block;
  top: 1.6em;
  right: 1.5em;
}

@media (min-width: 800px) {
  .navToggle {
    display: none;
  }
}

header .nav.open .nav__items {
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

header .nav__items {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@media (min-width: 800px) {
  header .nav__items {
    display: flex;
  }
}

header .nav__item {
  margin: 0;
  padding: 10px;
}

header .nav__item a {
  display: inline-block;
}

.editor-nav ul {
  padding: 0;
  list-style-type: none;
}

.editor-nav .nav__items {
  margin: 0;
  display: flex;
}

.editor-nav .nav__item button {
  background: none;
  border: 0;
  padding: 10px;
}

.editor-nav .nav__item > button:hover, .editor-nav .nav__item--open > button {
  background-color: #eee;
}

.editor-nav .nav__dropdown {
  z-index: 5;
  display: none;
  position: absolute;
  box-shadow: 1px 1px 5px #0003;
}

.editor-nav .nav__item--open .nav__dropdown {
  display: block;
}

.editor-nav .nav__dropdown .nav__dropdown-item button {
  width: 100%;
  text-align: left;
  background-color: #eee;
  display: block;
}

.editor-nav .nav__dropdown .nav__dropdown-item button:hover {
  background-color: #ccc;
}

.navIsOpen main {
  display: none;
}

main {
  grid-template-rows: auto auto 1fr;
  padding: 0 3em;
  display: grid;
}

.editor-header {
  margin: 1em 0;
}

.editor-header > div {
  align-items: center;
  gap: 1em;
  margin: 1em;
  display: flex;
}

h2.filename {
  margin: 0;
}

.filename-ext {
  color: #aaa;
}

.formatting {
  font-size: .8em;
}

.commentBtns button {
  font-style: italic;
}

.stringBtns button {
  font-family: IBM Plex Sans, sans-serif;
}

.regexBtns button {
  font-family: IBM Plex Mono, monospace;
}

.regex {
  display: none;
}

.regex.visible {
  display: block;
}

.regex .backdrop {
  background-color: #0000004d;
  position: fixed;
  inset: 0;
}

.regex .window {
  width: 50%;
  max-width: 400px;
  background-color: #fff;
  border-radius: 5px;
  padding: 50px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 5px 5px 20px #0003;
}

.regex .identifier {
  font-family: IBM Plex Mono, monospace;
}

.navIsOpen footer {
  display: none;
}

footer {
  text-align: center;
  margin: 2em;
}

/*# sourceMappingURL=index.ea483f87.css.map */
