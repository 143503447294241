*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --background-color: white;
  --text-color: black;
  --accent-color: blue;
}

html {
  height: 100%;
}

body {
  /* margin: 3em; */
  height: 100%;
  /* max-width: 1200px; */
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 1em;
  font-family: "IBM Plex Serif", serif;
}

a {
  color: var(--accent-color);
  text-decoration: none;
  border-bottom: 1px solid currentColor;
}

button {
  cursor: pointer;
  font-family: "IBM Plex Serif", serif;
  font-size: 1em;
}

kbd {
  display: inline-block;
  margin: 0.1em;
  padding: 0.2em 0.5em;
  background-color: #eee;
  border-radius: 5px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.05);
}

/*
      ___       __   ___  __
|__| |__   /\  |  \ |__  |__)
|  | |___ /~~\ |__/ |___ |  \

*/

header {
  width: 100%;
  /* max-width: 1200px; */
  /* margin: 3em; */
  /* margin-left: 2em; */
  /* margin-right: 2em; */
  padding: 1em 3em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  margin: 0;
}

.title a {
  color: var(--text-color);
  border: none;
}

header .nav {
  display: none;
}
  @media (min-width: 800px) {
    header .nav {
      display: block;
    }
  }

header .nav.open {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.navToggle {
  top: 1.6em;
  right: 1.5em;
  display: block;
  font-size: 2em;
  border: 0;
  z-index: 2;
  font-family: var(--font-family);
  background: none;
  padding: 0;
  cursor: pointer;
}
  @media (min-width: 800px) {
    .navToggle {
      display: none;
    }
  }

header .nav.open .nav__items {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
}

header .nav__items {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

  @media (min-width: 800px) {
    header .nav__items {
      display: flex;
    }
  }

header .nav__item {
  padding: 10px;
  margin: 0;
}

header .nav__item a {
  display: inline-block;
}

.editor-nav {
  /* grid-column: 2; */
  /* padding-left: 28px; */
}

.editor-nav ul {
  list-style-type: none;
  padding: 0;
}

.editor-nav .nav__items {
  margin: 0;
  display: flex;
  /* margin-left: 25px; */
}

.editor-nav .nav__item button {
  border: 0;
  padding: 10px;
  background: none;
}

.editor-nav .nav__item > button:hover,
.editor-nav .nav__item--open > button {
  background-color: #eee;
}

.editor-nav .nav__dropdown {
  position: absolute;
  display: none;
  z-index: 5;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
}

.editor-nav .nav__item--open .nav__dropdown {
  display: block;
}

.editor-nav .nav__dropdown .nav__dropdown-item button {
  display: block;
  width: 100%;
  text-align: left;
  background-color: #eee;
}

.editor-nav .nav__dropdown .nav__dropdown-item button:hover {
  background-color: #ccc;
}

/*

 |\/|  /\  | |\ |
 |  | /~~\ | | \|

*/

.navIsOpen main {
  display: none;
}

main {
  display: grid;
  /* grid-template-columns: 20% 60% 20%; */
  /* grid-template-columns: 70% 30%; */
  /* grid-template-rows: auto auto 1fr; */
  /* grid-template-rows: auto 1fr; */
  grid-template-rows: auto auto 1fr;
  /* margin-left: 2em; */
  /* margin-right: 2em; */
  /* gap: 20px; */
  padding: 0 3em;
}

.editor-header {
  /* grid-column: 2 / span 2; */
  /* display: flex; */
  /* align-items: center; */
  /* gap: 1em; */
  margin: 1em 0;
}

.editor-header > div {
  display: flex;
  gap: 1em;
  align-items: center;
  margin: 1em;
}

h2.filename {
  /* grid-column: 2 / span 2; */
  /* padding-left: 35px; */
  margin: 0;
}

.filename-ext {
  color: #aaa;
}

/* .toc {
  grid-row: 3;
}

.toc__items {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.toc__item button {
  width: 100%;
  text-align: left;
  padding: 10px;
  border: 0;
  background: none;
}

.toc__item button:hover {
  background-color: #eee;
}

.current button {
  background-color: #eee;
} */

.formatting {
  font-size: 0.8em;
}

.commentBtns button {
  font-style: italic;
}

.stringBtns button {
  font-family: "IBM Plex Sans", sans-serif;
}

.regexBtns button {
  font-family: "IBM Plex Mono", monospace;
}

.code {
  /* display: grid; */
  /* grid-template-columns: 50% 50%; */
  /* grid-template-rows: 1fr 150px; */
  /* gap: 1em; */
}

.editor {
  /* grid-row: 1 / span 2; */
}

/* .output {
  white-space: pre;
  font-family: "IBM Plex Mono", monospace;
  padding: 1em;
  font-size: 24px;
} */

/* POPUPS */

.regex {
  display: none;
}

.regex.visible {
  display: block;
}

.regex .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.3);
}

.regex .window {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  border-radius: 5px;
  box-shadow: 5px 5px 20px rgba(0,0,0,0.2);
  background-color: white;
  width: 50%;
  max-width: 400px;
}

.regex .identifier {
  font-family: "IBM Plex Mono", monospace;
}

/*
 ___  __   __  ___  ___  __
|__  /  \ /  \  |  |__  |__)
|    \__/ \__/  |  |___ |  \

*/

.navIsOpen footer {
  display: none;
}

footer {
  text-align: center;
  margin: 2em;
}